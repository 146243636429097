.aboutus {
    max-width: 1340px;
    padding: 0 20px;
    margin: 0 auto;
}
.aboutus__title {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #27272A;
    margin-bottom: 40px;
}
.aboutus__details {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #A1A1AA;
}
.aboutus__image {
    text-align: right;
    margin-top: 100px;
}
.aboutus__image-src {
    max-width: 500px;
    width: 100%;
}
@media screen and (max-width: 767px) {
    .aboutus__title {
        font-size: 24px;
        line-height: 32px;
    }
    .aboutus__details { 
        font-size: 16px;
        line-height: 24px;
    }
}