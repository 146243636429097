.lol-button {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}
.lol-button--primary {
  width: 100%;
  height: 52px;
  background: linear-gradient(274.21deg, #3D3284 0%, #D61874 99.14%);
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  min-width: 110px;
  line-height: 24px;
  text-transform: uppercase;
}
.lol-button--secondary {
  color: #27272A;
  background: 
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(to right, #D61874, #3D3284) border-box;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 10px;
  height: 44px;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
}
span.lol-icon {
  right: 5px;
  top: 2px;
  position: relative;
}
span.lol-icon.right {
  right: auto;
  left: 5px;
}
.lol-icon img {
  max-width: 22px;
}
@media screen and (max-width: 767px) {
  .lol-button--primary {
    font-size: 14px;
    line-height: 22px;
  }
}