.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  overflow: hidden;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 20%);
  height: 76px;
  padding: 0 60px;
}
.upload-video-header{
  margin-right: 16px;
}
.upload-video-header button{
  color: #fff;
  width: 165px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;  
  height: 48px;
}
.menu-header {
  width: 48px;
  height: 48px;
}
.mobile-menu {
  display: none;
}
.nav a {
  text-decoration: none;
}

.navLink:hover {
  background-color: #667B68;
}
.header-left {
  width: 100%;
  display: flex;
  align-items: center;
}
div.swal2-html-container {
  margin: 0;
}


#logo {
  height: 70px;
  width: 70px;
}

#logged-in-nav {
  display: flex;
  align-content: right;
  justify-content: space-between;
  right: 0%;
}

.navLinks {
  display: flex;
}

.header-signup, .header-login {
  min-width: 110px;
}
.menu-drawer > div {
  padding: 10px;
}
.menu-drawer .menu-close {
  text-align: right;
}
.menu-drawer li svg {
  margin-right: 10px;
}
.MuiAutocomplete-listbox li {  
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Poppins', Arial, Helvetica;
  padding: 9px 16px !important;
  color: #3F3F46 !important;
}
@media screen and (max-width: 1500px) {
  .nav {    
    padding: 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .mobile-menu {
    display: block;
  }
  .desktop-menu, .desktop-search .search-input { 
    display: none;
  }
  .search-input .search-icon {
    right: auto !important;
    left: 24px;
  }
  .search-input label {
    padding-left: 24px;
  }
  .search-input input {
    padding-left: 30px !important;
  }
  .nav {
    box-shadow: none;
  }
}