.input-control .lol-input {
  width: 100%;
}
.input-control .lol-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #27272A;
  margin-bottom: 4px;
}
.input-control fieldset , .input-control .Mui-focus fieldset {
  border: none;
}
.input-control {
  margin-bottom: 16px;
}
.input-control .lol-input input {
  width: 100%;
  background-color: #F4F4F5;
  padding: 8px 10px; 
  border-radius: 16px;  
  border: 1px solid #D4D4D8;
  height: 52px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #A1A1AA;
}
.input-control .lol-input--bgwhite input, .input-control .lol-input.active input{
  background-color: #fff;
  color: #27272A;
}
.lol-input input:focus {
  background-color: #fff;
  border: 1px solid #D4D4D8;
  color: #27272A;
}
.lol-input::after, .lol-input::before {
  border: none !important;
}