.input-control .lol-input {
  width: 100%;
}
.input-control .lol-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #27272a;
  margin-bottom: 4px;
}
.lol-select {
  width: 100%;
  height: 52px;
  border: 1px solid #d4d4d8;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  outline: none;
  cursor: pointer;
}
