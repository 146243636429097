.images {
    width: 100%;
    position: relative;
}

.profile-image__block {
    position: relative;
    width: 160px;
    height: 160px;
    margin: 0 auto;
}

#bannerButton {
    position: absolute;
    right: 20px;
    bottom: 20px;
    min-width: 0px;
    height: 35px;
    background-color: rgb(255, 255, 255, 0.2);
}
#bannerButton span {
    margin: 0;
}
.bannerimage {
    width: 100%;
    object-fit: cover;
    height: 100%;
    background-color: #667b68;
    max-height: 320px;
    border-radius: 16px;
}


.profile-img-div {
    position: absolute;    
    bottom: 15px;
    left: 120px;
}

.profile-img {
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #fff;
    width: 160px;
    height: 160px;
}

.info-beneath-photos {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
}
.name-and-location .user-details__name {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #27272A;    
    font-family: 'Poppins', Arial, Helvetica;
    margin-top: 30px;
    text-transform: capitalize;
}
.name-and-location .user-details__location {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #A1A1AA;    
    font-family: 'Poppins', Arial, Helvetica;
    text-transform: capitalize;
}
.profile-info {
    width: 90%;
    margin: 50px 20px;
    display: inline-flex;
    justify-content: right;
}

.name-and-location {
    margin-left: 250px;
    width: 100%;
    text-align: left;
    position: relative;
}

.manage-library {
    margin-right: 10px;
    position: absolute;
    top: 16em;
    right: 11em;
}
.profile-header {
    padding: 34px 60px 48px;
    position: relative;
}
.my-connections {
    margin-right: 10px;
    position: absolute;
    top: 16em;
    right: 0;
}
.banner-block {
    position: relative;
}
.profilePicBtn {
    float: right;
    position: absolute;
    right: 0;
}

.searchAboutMe {
    margin: 8em;
    width: 26em;
    overflow-y: scroll;
    position: absolute;
    top: 7em;
    left: 30em;
}

.no-image-active {
    display: none;
}

.full-width {
    width: 100%;
}
.connect-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 140px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.swal2-icon.swal2-warning {
    color: #fff;
    background: #E8408E;
    border: none;
    width: 60px;
    height: 60px;
    font-size: 10px;
}
@media screen and (max-width: 1500px) {
    .profile-header {
        padding: 34px 20px 48px;
    }
    .profile-img-div {
        left: 80px;
    }
}
@media screen and (max-width: 767px) {
    .profile-header {
        padding: 0;
    }
    .bannerimage {
        border-radius: 0;
    }
    .profilePicBtn {
        float: none;
        position: relative;
    }
    .info-beneath-photos {
        margin: 0;
        display: block;
    }
    .profile-img-div {
        position: relative;
        margin-top: -40px;
        z-index: 9;
        left: 0;
        bottom: 0;
        text-align: center;
    }
    .name-and-location {
        margin-left: 0;
        text-align: center;
    }
    .name-and-location .user-details__name {
        font-size: 20px;
        line-height: 28px;
    }
    .name-and-location .user-details__location {
        font-size: 14px;
        line-height: 22px;
    }
    .profile-image__block {
        margin: 20px auto 0;
    }
    .connect-button {
        position: static;
        margin: 20px 0;
    }
}