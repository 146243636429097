.banner-modal-container {
  width: 668px;
  height: 814px;
  border-radius: 0;
  padding: 0px !important;
  box-sizing: border-box;
}

.banner-header {
  font-size: 24px;
  text-align: left;
  padding: 10px 21px 0;
}

.banner-item {
  justify-content: space-between;
  flex-direction: row !important;
  padding: 16px;
}

.custom-radio input[type='radio'] {
  display: none;
}

.banner-item-container {
  display: flex;
  width: 290px;
  height: 218px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 12px;
  border: 1px solid #e4e4e4;
  box-shadow: none !important;
  box-sizing: border-box;
  cursor: pointer;
}

.banner-item-card {
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
}

.banner-item-img {
  height: 170px;
}

.p-0 {
  padding: 0 !important;
}

.banner-button {
  width: 160px;
  height: 48px;
}

.active {
  border: 1px solid #D61874;
}

@media only screen and (max-width: 600px) {
  .banner-item-container {
    width: 146px;
    height: 104px;
    margin: 5px;
  }

  .banner-item-img {
    height: 70px;
  }
  .banner-item-card {
    height: 34px;
  }
  .banner-modal-container {
    width: 340px;
    height: 476px;
  }
  .banner-button {
    width: 100%;
    height: 48px;
  }
}