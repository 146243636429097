.mb-1 {
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}
.upload-video__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 20px);
  max-width: 892px;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  box-sizing: border-box;
}
.upload-video__modal .dzu-dropzone {    
  width: 100%;
  min-height: 202px;
  text-align: center;
  overflow: hidden;
  border: 4px dashed #E8408E;
  background-color: #FAE4EF;
  border-radius: 8px;
}
.upload-video__modal .dzu-inputLabel:after {
  bottom: 90px;
}
.upload-video__modal .upload-video__modal-title {
  margin-bottom: 32px;
  text-align: left;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;  
  font-family: 'Poppins', Arial, Helvetica;
}
.upload-video__modal .upload-video__modal-prompt {
  margin-bottom: 24px;
}
.dzu-dropzone .dzu-inputLabel {
  padding: 40px;
} 
.upload-video__modal-prompt label, .upload-video__modal-dropdown #demo-simple-select, .share-dropwdown #demo-multiple-checkbox {  
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; 
  font-family: 'Poppins', Arial, Helvetica;
  color: #3F3F46;
  text-align: left;
}
.upload-video__modal .upload-video__modal-dropdown {
  border-radius: 16px;
}
.swal2-icon.swal2-error {
  border-color: #E8408E !important;
  background-color: #E8408E !important;
  width: 60px;
  height: 60px;
  margin-top: 14px !important;
}
.swal2-actions {
  margin-top: 50px !important;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  background-color: #fff !important;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left], .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  width: 35px !important;
  top: 28px !important;
  left: 12px !important;
}
.swal2-icon .swal2-html-container {
  margin: 0 !important;
}
.upload-video__modal-dropdown svg, .share-list svg, .share-dropwdown svg {
  display: none;
}
.share-dialog .share-dropwdown {
  border-radius: 16px;
}
.share-dialog__text{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #A1A1AA;
}
.share-dialog__get-link {
  margin: 32px 0 16px;
}
.tiny-share-link {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.share-dialog__link-copied {
  margin: 24px 0 0;
}
.share-dialog .share-buttons button {
  margin-top: 32px;
}
.upload-video__modal-dropdown #demo-simple-select:after, .share-list:after, .share-dropwdown #demo-multiple-checkbox:after {
  content: '';
  background-image: url(./DropdownArrow.png);
  width: 16px;
  height: 10px;
  position: absolute;
  right: 24px;
  top: 24px;
}
.share-dialog .MuiPaper-root.MuiPaper-elevation {
  width: 620px;
  border-radius: 16px;
  padding: 20px;
  text-align: center;
}
.upload-prompt-dialog .swal2-html-container {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #A1A1AA;
  overflow: visible;
  margin-top: 8px;
}
.upload-prompt-dialog .swal2-actions {
  margin-top: 30px !important;
}
.upload-prompt-dialog .swal2-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #27272A;
}
.share-dialog .MuiDialogContent-root {
  padding: 10px 0 0;
}
.share-dialog p {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.share-dialog .share-buttons {
  justify-content: center;
}
.share-link-button {
  width: auto;
  padding: 0 15px;
}
.share-inputfield {
  width:100%;
}
.share-buttons button {
  width: auto;
  height: 44px;
  min-width: 140px;
  margin-top: 20px;
}
.share-list:after {
  right: 16px;
  top: 16px;
}
.dzu-previewButton {
  background-image: url(./close-icon.png) !important;
}
.video-upload-mobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .dzu-previewContainer {
    display: block;
  }
  .dzu-previewStatusContainer {
    justify-content: space-around;
    margin-top: 8px;
  }
  .video-upload-mobile {
    display: block;
    cursor: pointer;
  }
  .video-upload-desktop {
    display: none;
  }
  .upload-video-header {
    min-width: 10px;
    height: auto;
    margin-left: 15px;
  }
  .share-dialog p {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  .share-buttons button {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}

.upload-video_loading-spinner {
  height: 283px;
  display: flex;
  justify-content: center;
  align-items: center;
}