.loginFormBox {
  margin-top: 40px;
}
.loginFormBox .formPanel {
  width: 100%;
  text-align: left;
}
.loginFormBox div {
  width: 100%;
}
.loginFormBox input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 5px 0;
  font-size: 14px;
  line-height: 22px;
  background-color: #f4f4f5;
}
.loginFormBox input:focus {
  background-color: #fff;
}
.loginFormBox div::before,
.loginFormBox div::after {
  border: 0 !important;
}
.login-button {
  width: 100%;
}
.login-form__heading {
  margin-bottom: 0;
}
.login-form__details {
  margin-top: 4px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 80px;
  font-weight: 400;
  color: #71717a;
}
.login-button {
  margin-top: 100px;
}
.login-form .forgot-password--link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  cursor: pointer;
}
.login-form .forgot-password {
  display: flex;
  justify-content: right;
}
.mobile-register {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  display: none;
}
.mobile-register a {
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .mobile-register {
    display: block;
  }
  .mobile-register a {
    color: #d61874;
    text-decoration: none;
    cursor: pointer;
  }
  .login-form--button-block {
    text-align: center;
  }
  .loginFormBox {
    margin-top: 22px;
  }
  .login-form__details {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 28px;
  }
}
