.connectionsImage {
    width: 80px;
    height: 80px;
    border-radius: 100%;
}
.connections__block {
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 60px;
}
.connections__tabs{
    text-align: left;
    border-bottom: 1px solid #D4D4D8;
    margin-bottom: 20px;
}
.connections__tabs .connections__tab-item {
    min-width: 210px;
    border: 0;
}
.connections__details {
    padding: 0 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    width: 100%;
}
.connections__list {
    display: flex;
    flex-wrap: wrap;
}
.connections__list .connections__list-item {
    display: flex;    
    width: calc(25% - 30px);
    padding: 16px 20px;
    align-items: center;
    box-sizing: border-box;
    border: 1.5px solid #E4E4E7;
    border-radius: 8px;
    box-shadow: none;
    margin-right: 30px;
    min-width: 360px;
    margin-bottom: 30px;
}
.connections__list .connections__list-item:nth-child(4n) {
    margin-right: 0;
    width: 25%;
}
.connections__tabs .connections__tab-item.Mui-selected {
    background-color: #fff;
    border-bottom: 1px solid #27272A;
}
.connections__tabs .connections__tab-item.Mui-selected {
    color: #27272A;
}
.connections__tabs .connections__tab-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #A1A1AA;
}
.connections__list-item .connectionsRemove,
.connections__list-item .connectionsAccept {
    width: 107px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}
.connections__list-item .connectionsAccept  {
    margin-bottom: 12px;
}
.request__count span {
    background: linear-gradient(274.21deg, #3D3284 0%, #D61874 99.14%);
    color: #fff;
}
.connections__details-relation, .connections__details-location {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A1A1AA;
    text-transform: capitalize;
}
.remove-connection .swal2-html-container {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #A1A1AA;
    overflow: visible;
    margin-top: 8px;
}
.remove-connection .swal2-actions {
    margin-top: 30px !important;
}
.remove-connection .swal2-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #27272A;
}
.connections__tabs .connections__tab-block {
    display: flex;
}
@media screen and (max-width: 1680px) {
    .connections__list .connections__list-item {   
        width: calc(33.33% - 30px);
    }
    .connections__list .connections__list-item:nth-child(4n) {
        margin-right: 30px;
        width: calc(33.33% - 30px);
    }
    .connections__list .connections__list-item:nth-child(3n) {
        margin-right: 0;
        width: 33.33%;
    }
}
@media screen and (max-width: 1210px) {
    .connections__list .connections__list-item {   
        width: calc(50% - 30px);
    }
    .connections__list .connections__list-item:nth-child(3n) {
        margin-right: 30px;
        width: calc(50% - 30px);
    }
    .connections__list .connections__list-item:nth-child(2n) {
        margin-right: 0;
        width: 50%;
    }
}
@media screen and (max-width: 820px) {
    .connections__list .connections__list-item,
    .connections__list .connections__list-item:nth-child(4n),
    .connections__list .connections__list-item:nth-child(3n),
    .connections__list .connections__list-item:nth-child(2n) {
        margin-right: 0;
        width: 100%;
    }
}
@media screen and (max-width: 1500px) {
    .connections__block {
        padding: 0 20px;
    }
}

@media screen and (max-width: 767px) {
    .remove-connection .swal2-html-container {
        font-size: 14px;
        line-height: 22px;
    }
    .remove-connection .swal2-title {
        font-size: 20px !important;
        line-height: 28px;
    }
    .swal2-popup .swal2-popup {
        padding: 8px;
    }
    .connections__tabs .connections__tab-item {
        min-width: 25%;
    }
    .connections__list .connections__list-item {
        min-width: 100%;
        margin-right: 0;
        width: 100%;
    }
    .connections__list-item .connectionsRemove,
    .connections__list-item .connectionsAccept {
        width: 93px;
        height: 34px;
    }
    .connectionsImage {
        width: 60px;
        height: 60px;
    }
}



