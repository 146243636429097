.lol-title.lol-page--title {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #27272A;
  font-family: 'PoppinsSemiBold', Arial, Helvetica;
}
@media screen and (max-width: 767px) {
  .lol-title.lol-page--title {
    font-size: 20px;
    line-height: 28px;
  }
}