.registrationFormBox {
    margin-top: 40px;
}
.registrationFormBox .formPanel {
    width: 100%;
    text-align: left;
}
.registrationFormBox div {
    width: 100%;
}

.registrationFormBox div::before, .registrationFormBox div::after {
    border: 0 !important;
}
.register-button {
    width: 100%;   
}
.register-form__heading {
    margin-bottom: 0;
}
.input-column2 {
    display: flex;
    
}
.input-column2 .input-control:first-child {
    margin-right: 5px;
}
.input-column2 .input-control:last-child {
    margin-left: 5px;
}
.mobile-register {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    display: none;
}
.mobile-register a {
    font-weight: bold;
}
@media screen and (max-width: 767px) {
    .mobile-register {
        display: block;
    }
    .mobile-register a {
        color: #D61874;
        text-decoration: none;
        cursor: pointer;
    }
    .registrationFormBox {
        margin-top: 22px;
    }
    .input-column2 {
        display: block;
    }
}