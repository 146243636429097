#video-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

#permissions-and-share {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

#delete-button {
    margin-left: 20px;
}

.video-container .card-action {
    display: block;
    padding: 16px;
}
.share-list {
    width: 100%;
    box-sizing: border-box;
}
.card-action .card-view {
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 17px;
    margin-left: 0;
}
.share-button {
    width: calc(100% - 53px);
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
}
.manage-library-container h1 {
    margin: 48px 0 0;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;    
    font-family: 'Poppins', Arial, Helvetica;
}
.share-button svg {
    font-size: 16px;
}
.delete-video-btn {
    width: 53px;
    height: 52px;
    cursor: pointer;
}
.delete-video-btn img {
    height: 100%;
}
.video-items {
    position: relative;
    box-sizing: border-box;
    margin-top: 36px;
}
.video-listing .video-title {
    position: absolute;
    z-index: 9;
    color: #fff;
    padding: 12px 14px;
    text-align: left;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    font-family: 'Poppins', Arial, Helvetica;
}

.share-message {
    padding: 16px;
}

@media screen and (max-width: 767px) {
    .manage-library-container h1 {
        font-size: 24px;
        line-height: 32px;
    }
}