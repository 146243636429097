@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('../../fonts/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: 'PoppinsMedium';
  src: url('../../fonts/Poppins-Medium.ttf');
}
html,
body,
#react-root {
  height: 100%;
}

body {
  font-family: 'Poppins', Arial, Helvetica;
  margin: 0px;
}

.centerAbout {
  text-align: center;
}

.alert {
  box-sizing: border-box;
  padding: 15px 10px;
  margin: 15px 0;
  border: 2px solid #e01616;
  background-color: #efefef;
  border-radius: 3px;
  color: #e01616;
  font-size: 0.8rem;
}

 .formPanel {
  text-align: center;
} 

/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #A1A1AA;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}