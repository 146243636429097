body {
  text-align: center;
}

.focalImage {
  height: 25%;
  width: 65%;
  border-radius: 5px;
}
#bannerButton {
  min-width: 30px;
  padding: 0;
  height: 30px;
}
#bannerButton span {
  margin: 0;
}