.edit-profile {
    max-width: 712px;
    margin: 0 auto;
}

.edit-profile .input-control {
    text-align: left;
    margin: 0 15px 16px;
}
.edit-profile .save-button {
    max-width: 180px;
    margin-top: 38px;
    margin-bottom: 100px;
}
.edit-profile .lol-page--title {
    font-size: 24px;
    margin: 24px 0 32px;
}
.no-image {
    width: 160px;
    height: 160px;
    border-radius: 100%;
    margin: 0 auto;
    border: 4px solid #FFFFFF;
    box-shadow: 0px 4px 8px rgba(227, 227, 254, 0.4);
    border-radius: 80px;
    background-color: #fff;
}
.no-image svg {
    font-size: 160px;
    color: #e6e6e6;
}
.edit-button {
    width: 44px;
    height: 44px;
    min-width: 44px;
    position: absolute;
    bottom: 0;
    right: 0;
}