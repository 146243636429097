.footer {
  margin: 2em 60px 0;
  padding: 1em;
  text-align: center;
  display: flex;
  justify-content: space-between;
  color: #71717A;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  flex-direction: row-reverse;
  clear: both;
}
.footer .footer-link {
  margin-left: 16px;
  text-decoration: none;
}
.main-footer {
  box-sizing: border-box;
}
@media screen and (max-width: 1500px) {
  .footer {
    margin: 2em 20px 0;
  }
}
@media screen and (max-width: 767px) {
  .footer {
    display: block;
  }
  .footer-links {    
    padding: 14px;
  }
  .footer-copyright {
    border-top: 1px solid #E4E4E7;
    padding: 14px;
  }
  .footer .footer-link {
    margin-left: 8px;
    text-decoration: none;
  }
  .footer .footer-link:first-child {
    padding-right: 8px;
    border-right: 1px solid #A1A1AA;
  }
}