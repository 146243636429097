.share-icon {
  padding-left: .3rem;
}
div.search-input {
  width: 520px;
  margin: 0 28px;
  height: 48px;
  position: relative;
}
.search-input input,
.search-input label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Poppins', Arial, Helvetica;
  height: auto;
  color: #3F3F46;
}
.search-input fieldset {
  border: 1px solid #E4E4E7;
  border-radius: 16px;
}
.search-input svg {
  display: none;
}
.search-input .search-icon{
  display: block;
  position: absolute;
  right: 14px;
  top: 14px;
  color: #3F3F46;
  width: 16px;
}
.video-share-select {
  text-align: left;
}
.video-share-select fieldset {
  border-radius: 8px;
}
.video-share-select legend, .video-share-select .MuiSelect-select {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.edit-profile-button {
  width: 158px;
  margin: 16px 0;
  position: relative;
  top: -70px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.mobile-search {
  display: none;
}
.css-1jrarms-MuiButtonBase-root-MuiCheckbox-root.Mui-checked svg {
  display: none;
}
.css-1jrarms-MuiButtonBase-root-MuiCheckbox-root.Mui-checked:after {
  content: '';
  background: url('checkbox.png') no-repeat;    
  width: 20px;
  height: 20px;
  margin-left: 3px;
  background-size: 20px;
}
@media screen and (max-width: 920px) {
  div.search-input {
    width: 320px;
  }
}
@media screen and (max-width: 767px) {
  .edit-profile-button {
    top: 0;
    margin-bottom: 40px;
  }
  div.search-input {
    width: 100%;
    margin: 0;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .navLinks .menu-header {
    width: auto;
    height: auto;
    padding: 00;
    border: none;
    background: none;
  }
  .mobile-search {
    display: block;
    margin: 2px;
    cursor: pointer;
  }
}

.delete-dialog .MuiPaper-root.MuiPaper-elevation {
  border-radius: 16px;
  width: 620px;
  padding: 20px;
}

.delete-dialog .delete-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.delete-dialog .delete-dialog-inner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.delete-dialog .delete-dialog-large-text {
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.delete-dialog .delete-dialog-small-text {
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 64px;
}

.delete-dialog .delete-dialog-button-row {
  display: flex;
  flex-direction: row;
}

.delete-dialog .delete-dialog-button {
  width: 140px;
  height: 44px;
  margin: 0px 5px 0px 5px
}
