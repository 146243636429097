.video-container {
    max-width: 1800px !important;
    width: 100% !important;
    padding: 0 60px !important;
    box-sizing: border-box;
}
.videos-tab {
    text-align: left;
    border-bottom: 1px solid #D4D4D8;
}
div.video-container .video-listing {
    padding: 0;
}
.video-container .video-listing > div {
    padding: 0 15px;
    max-width: 580px;
}
.video-listing .video-items {
    border-radius: 16px;
    border: 1.5px solid #E4E4E7;
    min-height: 50px;
    padding-bottom: 4px;
    box-shadow: none;
}
.video-container .videos-tab__item {
    border: none;
    width: 210px;
}
.video-container .videos-tab__item h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #A1A1AA;    
    font-family: 'Poppins', Arial, Helvetica;
    margin: 0;
}
.videos-tab__item.active h3 {
    color: #27272A;
}
.videos-tab__item.active {
    border-bottom: 1px solid #27272A;
    border-radius: 0;
}
@media screen and (max-width: 1500px) {
    .video-container {
        padding: 0 20px !important;
    }
}
@media screen and (max-width: 767px) {
    .videos-tab .videoToggle {
        width: 100%;
    }
    .video-container .videos-tab__item {
        min-width: 50%;
    }
    .video-container .video-listing > div {
        padding: 0;
    }
}