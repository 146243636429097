.centerRegister {
  text-align: center;
  margin-top: 10px;
}
.container {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
}
.login-banner {  
  background-color: #D61874;
  overflow: hidden;
  max-height: 100%;
}
.login-banner img {
  max-width: 620px;
  width: 100%;
  max-height: 100vh;
  float: left;
}
.login-form {
  width: 100%;
  padding: 20px 40px;
  position: relative;
  max-width: 712px;
  margin: 0 auto;
  min-height: 100vh;
  box-sizing: border-box;
}
.login-form .nav {
  border: none;
  box-shadow: none;
  height: auto;
  padding: 0;
}
.login-form .header-left {
  justify-content: space-between;
}
.login-form .nav a{
  float: left;
}
.login-form .footer {
  margin: 20px 0 0;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .login-form {
    padding: 20px;
  }
  .login-banner, .login-form .navLinks{
      display: none;
  }
}