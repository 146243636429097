.photo-upload__block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 20px);
    max-width: 620px;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    box-sizing: border-box;
}

.photo-upload__block .model-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #27272A;
    margin-bottom: 24px;
    text-align: left;
    font-family: 'Poppins', Arial, Helvetica;
}
.photo-upload__block .dzu-dropzone {    
    width: 100%;
    min-height: 218px;
    text-align: center;
    overflow: hidden;
    border: 4px dashed #E8408E;
    background-color: #FAE4EF;
    border-radius: 8px;
}
.dzu-dropzone .dzu-inputLabel {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #52525B;
    font-family: 'Poppins', Arial, Helvetica;
    align-items: flex-end;
    padding: 30px;
}
.photo-upload__block label.dzu-inputLabel:before {
    content: "PNG,JPG Files are allowed";
    cursor: pointer;
    position: absolute;
    bottom: 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #A1A1AA;
}
label.dzu-inputLabel:after {
    content: "";
    cursor: pointer;
    position: absolute;
    bottom: 112px;
    background-image: url(./upload-icon.png);
    width: 62px;
    height: 62px;
}
.dzu-submitButton, button.swal2-styled.swal2-confirm {
    height: 44px;
    background: linear-gradient(274.21deg, #3D3284 0%, #D61874 99.14%);
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    min-width: 110px;
    line-height: 24px;
    width: 140px;
}
.swal2-styled.swal2-cancel {
    height: 44px;
    width: 140px;
    background: 
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(to right, #D61874, #3D3284) border-box;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 10px;
    color: #27272A;
}
.swal2-actions:not(.swal2-loading) .swal2-styled.swal2-cancel:hover {
    background: 
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(to right, #D61874, #3D3284) border-box;
    padding: 10px;
    border: 1px solid transparent;
}
.swal2-icon.swal2-success {
    border-color: #E8408E;
    background-color: #E8408E !important;
    width: 60px;
    height: 60px;
    margin-top: 14px !important;
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
    background-color: #fff !important;
}
.swal2-icon.swal2-success .swal2-success-fix,
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right,
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right,
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
    border: none;
    background-color: transparent !important;
}
.swal2-title {
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 32px;
    color: #27272A !important;
    font-family: 'Poppins', Arial, Helvetica;
    padding: 0 !important;
}
.swal2-footer {
    position: absolute;
    top: 125px;
    text-align: center;
    width: calc(100% - 2em);
    border: none !important;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 24px;
    color: #A1A1AA !important;
}
.swal2-icon {
    margin-top: 1.5em !important;
}
.swal2-popup {
    border-radius: 16px !important;
    min-height: 284px;
    width: 660px !important;
    padding: 20px;
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
    top: 28px !important;
    right: 8px !important;
    width: 1.9375em !important;
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
    top: 32px !important;
    left: 10px !important;
    width: 20px !important;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
    background: linear-gradient(274.21deg, #3D3284 0%, #D61874 99.14%);
}
.upload-video__modal .upload-video__modal-title img {
  cursor: pointer;
  float: right;
}
