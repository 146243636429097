.videoWatch {
    text-align: center;
    font-size: 20px;
}

#video {
    height: 20em;
    width: 35em;
    display: inline-block;
}

